// Generated by Framer (7aa0232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {xVnEazx8s: {hover: true}};

const cycleOrder = ["xVnEazx8s"];

const variantClassNames = {xVnEazx8s: "framer-v-s2oe4x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, newTab, smoothScroll, title, width, ...props}) => { return {...props, bp31TjQBy: smoothScroll ?? props.bp31TjQBy, NUrsnrIij: newTab ?? props.NUrsnrIij, U1gPdA4r9: link ?? props.U1gPdA4r9, YwyRGS3Ya: title ?? props.YwyRGS3Ya ?? "Button Text"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string;newTab?: boolean;smoothScroll?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, YwyRGS3Ya, U1gPdA4r9, NUrsnrIij, bp31TjQBy, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xVnEazx8s", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-MbXJ3", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={U1gPdA4r9} openInNewTab={NUrsnrIij} smoothScroll={bp31TjQBy}><motion.a {...restProps} className={`${cx("framer-s2oe4x", className)} framer-zry1lt`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"xVnEazx8s"} ref={ref} style={{...style}} {...addPropertyOverrides({"xVnEazx8s-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7Rm9ydW0tcmVndWxhcg==", "--framer-font-family": "\"Forum\", sans-serif", "--framer-letter-spacing": "1px", "--framer-line-height": "120%", "--framer-text-color": "var(--extracted-r6o4lv)", "--framer-text-transform": "uppercase"}}>Button Text</motion.p></React.Fragment>} className={"framer-1n7hnqa"} fonts={["GF;Forum-regular"]} layoutDependency={layoutDependency} layoutId={"u54WnCFGt"} style={{"--extracted-r6o4lv": "var(--token-c5c4fdcb-9482-48bb-aedf-353188472aae, rgb(21, 21, 21))", "--framer-paragraph-spacing": "0px"}} text={YwyRGS3Ya} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-1rc9d97"} data-framer-name={"Button Border"} layoutDependency={layoutDependency} layoutId={"zv0O6wqDD"} style={{backgroundColor: "var(--token-c5c4fdcb-9482-48bb-aedf-353188472aae, rgb(21, 21, 21))"}}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-MbXJ3 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MbXJ3 .framer-zry1lt { display: block; }", ".framer-MbXJ3 .framer-s2oe4x { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 2px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-MbXJ3 .framer-1n7hnqa { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-MbXJ3 .framer-1rc9d97 { bottom: 0px; flex: none; height: 1px; left: 0px; position: absolute; width: 100%; z-index: 1; }", ".framer-MbXJ3 .framer-v-s2oe4x .framer-s2oe4x { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MbXJ3 .framer-s2oe4x { gap: 0px; } .framer-MbXJ3 .framer-s2oe4x > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-MbXJ3 .framer-s2oe4x > :first-child { margin-top: 0px; } .framer-MbXJ3 .framer-s2oe4x > :last-child { margin-bottom: 0px; } }", ".framer-MbXJ3.framer-v-s2oe4x.hover .framer-1rc9d97 { width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 21
 * @framerIntrinsicWidth 99.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"MUq7xOso1":{"layout":["auto","auto"]}}}
 * @framerVariables {"YwyRGS3Ya":"title","U1gPdA4r9":"link","NUrsnrIij":"newTab","bp31TjQBy":"smoothScroll"}
 */
const FramerQ3kdzq8JL: React.ComponentType<Props> = withCSS(Component, css, "framer-MbXJ3") as typeof Component;
export default FramerQ3kdzq8JL;

FramerQ3kdzq8JL.displayName = "Button Text";

FramerQ3kdzq8JL.defaultProps = {height: 21, width: 99.5};

addPropertyControls(FramerQ3kdzq8JL, {YwyRGS3Ya: {defaultValue: "Button Text", displayTextArea: false, title: "Title", type: ControlType.String}, U1gPdA4r9: {title: "Link", type: ControlType.Link}, NUrsnrIij: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}, bp31TjQBy: {defaultValue: false, title: "Smooth Scroll", type: ControlType.Boolean}} as any)

addFonts(FramerQ3kdzq8JL, [{family: "Forum", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/Q3kdzq8JL:default", url: "https://fonts.gstatic.com/s/forum/v18/6aey4Ky-Vb8Ew_IWMJMa3mnT.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/forum/v18/6aey4Ky-Vb8Ew_IWMJMa3mnT.ttf", weight: "400"}])